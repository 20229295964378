@import 'src/styles/variables.scss';

.value{
    font-size: 32px;
    font-family: monospace;
    background-color: lighten($green4, 20%);
    color: $dark-gray5;
    padding: 5px;
    border-radius: 3px;
}

.line{
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
