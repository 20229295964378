.button {
    border-radius: 49px !important;
    background-image: none !important;
    box-shadow: none !important;
    background-clip: border-box !important;
    font-weight: 500;
}

.styledButton{
    width: 100%;
    font-weight: 500;
}

.buttonContainer {
    flex-grow: 1;
}

.sizeContainer{
    text-align: center;
}
