@import '~normalize.css/normalize.css';
@import '~@blueprintjs/core/lib/css/blueprint.css';
@import './variables.scss';

html,
body {
    height: 100%;
    padding: 0;
    margin: 0;
    min-height: 300px;
}

body {
    user-select: none;
    background-color: $dark-gray1;
}

* {
    outline-style: none;
}

#root {
    height: 100%;
    display: flex;
}

.filler {
    flex-grow: 1;
    display: flex;
    justify-content: center;
    align-items: center;
}
