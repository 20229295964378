@import 'src/styles/variables.scss';

.root {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    padding-bottom: 5px;
    justify-content: space-between;
}

.title{
    overflow: hidden;
    text-overflow: ellipsis;
}

.information{
    font-size: 12px;
    text-align: right;
}
