

$dark-gray1: #182026;
$dark-gray2: #202b33;
$dark-gray3: #293742;
$dark-gray4: #30404d;
$dark-gray5: #394b59;

$gray1: #5c7080;
$gray2: #738694;
$gray3: #8a9ba8;
$gray4: #a7b6c2;
$gray5: #bfccd6;

$light-gray1: #ced9e0;
$light-gray2: #d8e1e8;
$light-gray3: #e1e8ed;
$light-gray4: #ebf1f5;
$light-gray5: #f5f8fa;

$white: #ffffff;

$blue1: #0e5a8a;
$blue2: #106ba3;
$blue3: #23c890;
$blue4: #2b95d6;
$blue5: #23c890;

$green1: #0a6640;
$green2: #0d8050;
$green3: #0f9960;
$green4: #15b371;
$green5: #3dcc91;

$orange1: #a66321;
$orange2: #bf7326;
$orange3: #d9822b;
$orange4: #f29d49;
$orange5: #ffb366;

$red1: #a82a2a;
$red2: #c23030;
$red3: #db3737;
$red4: #f55656;
$red5: #ff7373;

$vermilion1: #9e2b0e;
$vermilion2: #b83211;
$vermilion3: #d13913;
$vermilion4: #eb532d;
$vermilion5: #ff6e4a;

$rose1: #a82255;
$rose2: #c22762;
$rose3: #db2c6f;
$rose4: #f5498b;
$rose5: #ff66a1;

$violet1: #5c255c;
$violet2: #752f75;
$violet3: #8f398f;
$violet4: #a854a8;
$violet5: #c274c2;

$indigo1: #5642a6;
$indigo2: #634dbf;
$indigo3: #7157d9;
$indigo4: #9179f2;
$indigo5: #ad99ff;

$cobalt1: #1f4b99;
$cobalt2: #2458b3;
$cobalt3: #2965cc;
$cobalt4: #4580e6;
$cobalt5: #669eff;

$turquoise1: #008075;
$turquoise2: #00998c;
$turquoise3: #00b3a4;
$turquoise4: #14ccbd;
$turquoise5: #2ee6d6;

$forest1: #1d7324;
$forest2: #238c2c;
$forest3: #29a634;
$forest4: #43bf4d;
$forest5: #62d96b;

$lime1: #728c23;
$lime2: #87a629;
$lime3: #9bbf30;
$lime4: #b6d94c;
$lime5: #d1f26d;

$gold1: #a67908;
$gold2: #bf8c0a;
$gold3: #d99e0b;
$gold4: #f2b824;
$gold5: #ffc940;

$sepia1: #63411e;
$sepia2: #7d5125;
$sepia3: #96622d;
$sepia4: #b07b46;
$sepia5: #c99765;

$yellow: #f2f200;

$blynkGreen: #23C48E;
$blynkPurple: #5F7CD8;
$blynkBlue: #04C0F8;
$blynkOrange: #ED9D00;
$blynkRed: #D3435C;
$blynkWhite: #FFFFFF;
$blynkDarkGray: #212227;
$blynkBlack: #000000;

//$blynkGreen: $forest5;
//$blynkPurple: $indigo5;
//$blynkBlue: $blue5;
//$blynkOrange: $orange5;
//$blynkRed: $red5;
//$blynkWhite: #FFFFFF;
//$blynkDarkGray: $dark-gray3;
//$blynkBlack: #000000;
