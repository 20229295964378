@import 'src/styles/variables.scss';

.map{

    :global{
        .leaflet-control-container{
            display: none;
        }
    }
}
