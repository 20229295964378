@import 'src/styles/variables.scss';

$mainWidth: 480px;
$halfWidth: $mainWidth/2;

.root {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.workspace{
    overflow: auto;
    flex-grow: 1;
    color: $gray3;
    display: flex;
    justify-content: center;
}

.workspaceInner{
    position: absolute;
    left: calc(50% - #{$halfWidth});
    padding: 5px 0;

}

:global(.bp3-dark) .workspace{
    background-color: $dark-gray1;
}

.header {
    flex-grow: 0;
    flex-shrink: 0;
    background-color: $gray5;
    border-bottom: 1px solid $gray1;
    line-height: 30px;

    display: flex;
    justify-content: center;
}

.headerInner{
    min-width: $mainWidth;
    display: flex;
    justify-content: space-between;
    padding: 5px;
}

:global(.bp3-dark) .header{
    background-color: $dark-gray5;
    border-bottom: 1px solid $dark-gray3;
}

.headerTitle{
    font-size: 20px;
    margin-right: 10px;
}

.headerControls{
    display: flex;

    :global{
        *:not(:last-child) {
            margin-right: 5px;
        }
    }
}

.headerDelimiter{
    width: 1px;
    background-color: $gray1;
    margin: 3px 10px 3px 5px !important;
}

.widgetsArea {
    position: relative;
}

.selectAutoSync{
    min-width: 150px;
    top: -1px;
}
