.loading {
    flex-grow: 1;
    align-items: center;
    justify-content: center;
    display: flex;
    opacity: 0.5;
}

.root {


}

.chart {
    flex-grow: 1;
}

.header {
    display: flex;
    justify-content: space-between;
}

.widgetLabelContainer {
    display: flex;
}

.widgetLabel {
    margin-right: 10px;
}
