@import 'src/styles/variables.scss';

.ledContainer{
    flex-grow: 1;

}

.ledCircle{
    stroke-width: 1px;
}
