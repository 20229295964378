@import 'src/styles/variables.scss';

.container {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.controlButton {
    z-index: 2;
}
