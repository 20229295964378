.outputContainer{
    flex-grow: 1;
    display: flex;
    margin-bottom: 5px;
}
.output{
    flex-grow: 1;
    min-height: 100%;
    margin: 0 !important;
    padding: 5px 10px !important;
    overflow: auto;
}

.input{

}
